.item_wrapper {
  border: 1px solid #538316;
  width: 200px;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  will-change: transform;
  transition: all 0.3s ease-in;
}

.item__score {
  font-family: monospace;
  flex: 1;
  text-align: right;
}

.item__name,
.item__score,
.item__title {
  margin-left: 10px;
}