.wrapper {
  position: relative;
  width: 200px;
  height: 200px;
  top: 125px;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 0;
}

.seagull {
  width: 50px;
  height: 50px;
  position: relative;
  border: 2.5px solid rgb(58, 58, 58);
  overflow: hidden;
  border-radius: 50%;
  z-index: 0;
  opacity: 0.75;

  &.seagull2 {
    top: 125px;
    left: 100px;
  }
}

.arrow { 
  position: absolute;
  border-radius: 50%;
  top: 25px;
  left: 25px;
  background: white;
  width: 50px;
  height: 50px;
  margin: auto;
  overflow: hidden;
  background-clip: padding-box;
}

.ring {
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  animation: pulse 8s ease-out infinite;
  
  &.ring2 {
    animation-delay: 1000ms
  }
  
  &.ring3 {
    animation-delay: 2000ms
  }
  
  &.ring4 {
    animation-delay: 3000ms
  }
}

@keyframes pulse {
  0% {
      box-shadow: 0 0 0 7px rgba(#538316, 1);
      transform: scale(0);
      opacity: 0;
  }
  
  50% {
      box-shadow: 0 0 0 7px rgba(#538316, 0.66);
      opacity: 1;
  }
  
  85% {
      box-shadow: 0 0 0 7px rgba(#538316, 0.33);
  }
  
  100% {
      box-shadow: 0 0 0 7px rgba(#538316, 0);
      transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    top: 200px;
  }
}