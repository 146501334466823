.input {
  height: 40px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  font-weight: 200;
  transition: border-color 0.2s ease-in-out;
  &:focus {
    border-color: #333;
  }
}