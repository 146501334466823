.main {
  display: flex;
  position: relative;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 4px;
  background-color: white;
  padding: 1rem 1.3rem 1rem 1.3rem;
  box-shadow: 0 0 11px rgba(33,33,33,.2); ;
  transition: transform ease-in-out 0.275s;

  &:hover {
      transform: translateY(-3px);
    }
}

.content {
  font-size: 1rem;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}